import { createMachine, guard, immediate, invoke, reduce, state, transition } from 'robot3'
import { DateTime } from 'luxon'
import router from 'next/router'
import cloneDeep from 'lodash/cloneDeep'
import { getMarketDetailsByName } from '../../lib/MarketDetails'
import { isToday, isTomorrow } from '../../lib/date'
import * as Sentry from '@sentry/nextjs'
import { d } from 'robot3'

// ! logging to help debug state machine issues
if (process.env.NODE_ENV === 'development') {
  d._onEnter = function (machine, to, state, prevState, event) {
    console.log(`Enter state ${to}`)
    console.groupCollapsed(`Details:`)
    console.log(`Machine`, machine)
    console.log(`Current state`, state)
    console.log(`Previous state`, state)

    if (typeof event === 'string') {
      console.log(`Event ${event}`)
    } else if (typeof event === 'object') {
      console.log(`Event`, event)
    }

    console.groupEnd()
  }
}

const routeTo = async (url: string) => {
  return router.push(url)
}

export interface machineContext {
  history: string[]
  queryParams: string
  job?: {
    id?: string
    jobNumber?: string
    serviceLocation?: string
    vehicleInfo?: {
      make?: string
      model?: string
      year?: string
    }
    contact?: {
      id?: string
      email?: string
      fullName?: string
      phoneNumber?: string
      lead?: {
        serviceUrgency?: string
      }
    }
    services?: {
      name?: string
      customerPrice?: number
      inEstimate?: boolean
      items?: {
        customerPrice?: number
        productSelection?: {
          name?: string
        }
        type?: string
        units?: number
      }[]
    }[]
    priceInfo?: {
      subTotal?: number
      partsTotal?: number
      laborTotal?: number
      discounts?: {
        type?: string
        reason?: string
        amount?: number
        total?: number
      }[]
      promoCodes?: {
        code?: string
        coupon?: {
          name?: string
          discount?: {
            type?: string
            reason?: string
            amount?: number
            total?: number
          }
        }
      }[]
      discountTotal: number
      totalTax: number
      amountDue?: number
    }
    market?: string
    createdBy?: string
    partsLeadTimeInDays?: number
  }
  appointment?: DateTime
  formattedApptTime?: DateTime
  apptMmDdYy?: string
  apptDayOfWeek?: string
  apptTime?: string
  daysToAppt?: number
  sameDayApptsAvail?: number
  nextDayApptsAvail?: number
  marketTz?: string
  serviceAddress?: {
    'places-autocomplete': string
  }
  vinLp?: {
    VIN?: string
    licensePlate?: string
    licensePlateState?: {
      name?: string
      label?: string
    }
  }
  error: {
    message: string
  }
  updatedEmail?: string
  showNoAppointmentsDialog: boolean
  showGenericErrorDialog: boolean
  availabilitySnapshotIDs: string[]
  marketPhone: string
  formattedMarketPhone: string
  refetchJob: boolean
  outOfServiceError: boolean
  refetchAppointments: boolean
  showEstimateDialog: boolean
}

const context = (): machineContext => ({
  history: [],
  queryParams: '',
  error: { message: '' },
  showNoAppointmentsDialog: false,
  showGenericErrorDialog: false,
  availabilitySnapshotIDs: [],
  marketPhone: getMarketDetailsByName('default').phoneNumber,
  formattedMarketPhone: getMarketDetailsByName('default').phoneNumberTxt,
  refetchJob: false,
  outOfServiceError: false,
  refetchAppointments: false,
  showEstimateDialog: false,
})

// generic transition function for saving a single field to the context, allows for single level depth updates i.e. event["data"], but not event["data"]["field"]
// use to replace this oft-seen pattern:
// transition(
//   "event",
//   "nextState",
//   reduce((ctx: machineContext, event) => ({ ...ctx, field: event["data"] }))
// );
const saveSinglePropertyTransition = ({
  eventName,
  nextState,
  prop,
}: {
  eventName: string
  nextState: string
  prop: string
}) => {
  return transition(
    eventName,
    nextState,
    reduce((ctx: machineContext, event) => ({ ...cloneDeep(ctx), [prop]: event[prop] }))
  )
}
const changeStateWithoutNavigation = (nextState: string) => {
  return transition(nextState, nextState)
}

// states
export const MACHINE_ENTRY = 'machineEntry'
export const BASE_ADDRESS_SCREEN = 'baseAddressScreen'
export const BASE_APPOINTMENT_SCREEN = 'baseAppointmentScreen'
export const BASE_ESTIMATE_SCREEN = 'baseEstimateScreen'
export const BASE_VIN_LP_SCREEN = 'baseVinLpScreen'
export const BASE_FINAL_DETAILS_SCREEN = 'baseFinalDetailsScreen'
export const BASE_BOOKING_COMPLETE_SCREEN = 'baseBookingCompleteScreen'
export const ERROR_STATE = 'errorState'
export const IDLE = 'idle'

const ROUTE_TO_ADDRESS_SCREEN = 'routeToAddressScreen'
const ROUTE_TO_APPOINTMENT_SCREEN = 'routeToAppointmentScreen'
const ROUTE_TO_VIN_LP_SCREEN = 'routeToVinLpScreen'
const ROUTE_TO_FINAL_DETAILS_SCREEN = 'routeToFinalDetailsScreen'
const ROUTE_TO_SUCCESS_SCREEN = 'routeToSuccessScreen'
const SEND_RESET_STATE = 'resetState'

// events
export const VIEW_APPOINTMENTS_CLICKED = 'viewAppointmentsClicked'
export const QUERY_PARAMS_SAVED = 'queryParamsSaved'
export const OOS_OK_PRESSED = 'oosOkPressed'
export const GEN_ERR_OK_PRESSED = 'genErrOkPressed'
export const NO_APPT_OK_PRESSED = 'noApptOkPress'
export const NO_APPTS_FOR_SERVICE_ADDRESS = 'noAppointmentsForServiceAddress'
export const APPOINTMENT_COUNT_CAPTURED = 'captureApptCount'
export const APPOINTMENT_SAVED = 'saveAppointment'
export const FINAL_DETAILS_SUBMITTED = 'submitDetails'
export const DONE = 'done'
export const ERROR = 'error'
export const RESET = 'reset'
export const REFRESH = 'refresh'
export const EST_DIALOG_CLOSED = 'estDialogClosed'
export const EST_DIALOG_OPENED = 'estDialogOpened'
export const SCHEDULE_SERVICE_CLICKED = 'scheduleServiceClicked'
export const GENERIC_SUBMISSION_ERROR = 'genericSubmissionError'
// -- landing pages events
export const ESTIMATE_PAGE_REQUESTED = 'estimatePageRequested'
export const ADDRESS_PAGE_REQUESTED = 'addressPageRequested'

export const machine = createMachine(
  {
    // ** relatively linear state machine, errors grouped at bottom **
    // loaded customer info that we have, awaiting customer input plus click to view appts
    machineEntry: state(
      saveSinglePropertyTransition({
        eventName: QUERY_PARAMS_SAVED,
        nextState: MACHINE_ENTRY,
        prop: 'queryParams',
      }),
      transition(
        ESTIMATE_PAGE_REQUESTED,
        BASE_ESTIMATE_SCREEN,
        reduce((ctx: machineContext, { data: { getJobPublic: job }, marketPhone, formattedMarketPhone }) => ({
          ...ctx,
          job,
          marketPhone,
          formattedMarketPhone,
        }))
      ),
      transition(
        ADDRESS_PAGE_REQUESTED,
        BASE_ADDRESS_SCREEN,
        reduce((ctx: machineContext, { data: { getJobPublic: job }, marketPhone, formattedMarketPhone }) => ({
          ...ctx,
          job,
          marketPhone,
          formattedMarketPhone,
        }))
      )
    ),

    baseEstimateScreen: state(
      saveSinglePropertyTransition({
        eventName: QUERY_PARAMS_SAVED,
        nextState: BASE_ESTIMATE_SCREEN,
        prop: 'queryParams',
      }),
      saveSinglePropertyTransition({
        eventName: EST_DIALOG_CLOSED,
        nextState: BASE_ESTIMATE_SCREEN,
        prop: 'showEstimateDialog',
      }),
      saveSinglePropertyTransition({
        eventName: EST_DIALOG_OPENED,
        nextState: BASE_ESTIMATE_SCREEN,
        prop: 'showEstimateDialog',
      }),
      transition(SCHEDULE_SERVICE_CLICKED, ROUTE_TO_ADDRESS_SCREEN),
      transition(
        GEN_ERR_OK_PRESSED,
        BASE_ESTIMATE_SCREEN,
        reduce((ctx: machineContext) => ({ ...ctx, showGenericErrorDialog: false }))
      ),
      changeStateWithoutNavigation(BASE_ADDRESS_SCREEN)
    ),

    baseAddressScreen: state(
      saveSinglePropertyTransition({
        eventName: QUERY_PARAMS_SAVED,
        nextState: BASE_ADDRESS_SCREEN,
        prop: 'queryParams',
      }),
      saveSinglePropertyTransition({
        eventName: VIEW_APPOINTMENTS_CLICKED,
        nextState: ROUTE_TO_APPOINTMENT_SCREEN,
        prop: 'serviceAddress',
      }),
      transition(
        OOS_OK_PRESSED,
        BASE_ADDRESS_SCREEN,
        reduce((ctx: machineContext) => ({ ...ctx, outOfServiceError: false }))
      ),
      transition(
        REFRESH,
        SEND_RESET_STATE,
        reduce((ctx: machineContext) => ({ ...ctx, refetchJob: true, showGenericErrorDialog: true }))
      ),
      saveSinglePropertyTransition({ eventName: ERROR, nextState: ERROR_STATE, prop: ERROR }),
      transition(RESET, SEND_RESET_STATE),
      saveSinglePropertyTransition({
        eventName: NO_APPTS_FOR_SERVICE_ADDRESS,
        nextState: ROUTE_TO_ADDRESS_SCREEN,
        prop: 'showNoAppointmentsDialog',
      }),
      transition(
        NO_APPT_OK_PRESSED,
        BASE_ADDRESS_SCREEN,
        reduce((ctx: machineContext) => ({ ...ctx, showNoAppointmentsDialog: false }))
      ),
      transition(
        GEN_ERR_OK_PRESSED,
        BASE_ADDRESS_SCREEN,
        reduce((ctx: machineContext) => ({ ...ctx, showGenericErrorDialog: false }))
      ),
      changeStateWithoutNavigation(BASE_ESTIMATE_SCREEN),
      changeStateWithoutNavigation(BASE_APPOINTMENT_SCREEN)
    ),

    refetchAppointments: invoke(
      (ctx: machineContext) => routeTo(`/book-now/select-appointment?${ctx.queryParams}`),
      transition(DONE, BASE_APPOINTMENT_SCREEN),
      transition(ERROR, ERROR_STATE)
    ),

    // appt selection screen
    baseAppointmentScreen: state(
      transition(
        'refetchedAppointments',
        'baseAppointmentScreen',
        reduce((ctx: machineContext) => ({
          ...ctx,
          refetchAppointments: false,
        }))
      ),
      transition(
        APPOINTMENT_SAVED,
        'routeToNextScreen',
        reduce((ctx: machineContext, ev: any) => ({
          ...ctx,
          appointment: ev.data,
          formattedApptTime: ev.formattedApptTime,
          apptMmDdYy: ev.apptMmDdYy,
          apptDayOfWeek: ev.apptDayOfWeek,
          apptTime: ev.apptTime,
          sameDayApptsAvail: ev.sameDayApptsAvail,
          nextDayApptsAvail: ev.nextDayApptsAvail,
          marketTz: ev.marketTz,
          daysToAppt: ev.daysToAppt,
        }))
      ),
      changeStateWithoutNavigation(BASE_ADDRESS_SCREEN),
      changeStateWithoutNavigation(BASE_VIN_LP_SCREEN),
      changeStateWithoutNavigation(BASE_FINAL_DETAILS_SCREEN),
      saveSinglePropertyTransition({
        eventName: 'OutOfServiceAreaError',
        nextState: ROUTE_TO_ADDRESS_SCREEN,
        prop: 'outOfServiceError',
      }),
      saveSinglePropertyTransition({ eventName: ERROR, nextState: ERROR_STATE, prop: ERROR }),
      transition(RESET, SEND_RESET_STATE),
      transition(
        NO_APPTS_FOR_SERVICE_ADDRESS,
        ROUTE_TO_ADDRESS_SCREEN,
        reduce((ctx: machineContext) => ({ ...ctx, showNoAppointmentsDialog: true }))
      ),
      saveSinglePropertyTransition({
        eventName: 'saveAvailabilitySnapshotIDs',
        nextState: BASE_APPOINTMENT_SCREEN,
        prop: 'availabilitySnapshotIDs',
      })
    ),
    routeToNextScreen: state(
      immediate(
        ROUTE_TO_VIN_LP_SCREEN,
        guard((ctx: machineContext) => {
          return isToday(ctx.appointment, ctx.marketTz) || isTomorrow(ctx.appointment, ctx.marketTz)
        })
      ),
      immediate(ROUTE_TO_FINAL_DETAILS_SCREEN)
    ),

    // vehicle id screen
    baseVinLpScreen: state(
      saveSinglePropertyTransition({
        eventName: 'vehicleIdEntered',
        nextState: ROUTE_TO_FINAL_DETAILS_SCREEN,
        prop: 'vinLp',
      }),
      saveSinglePropertyTransition({ eventName: ERROR, nextState: ERROR_STATE, prop: ERROR }),
      changeStateWithoutNavigation(BASE_APPOINTMENT_SCREEN),
      changeStateWithoutNavigation(BASE_FINAL_DETAILS_SCREEN),
      transition(RESET, SEND_RESET_STATE)
    ),

    // final details screen
    baseFinalDetailsScreen: state(
      saveSinglePropertyTransition({
        eventName: EST_DIALOG_CLOSED,
        nextState: BASE_FINAL_DETAILS_SCREEN,
        prop: 'showEstimateDialog',
      }),
      saveSinglePropertyTransition({
        eventName: EST_DIALOG_OPENED,
        nextState: BASE_FINAL_DETAILS_SCREEN,
        prop: 'showEstimateDialog',
      }),
      saveSinglePropertyTransition({
        eventName: FINAL_DETAILS_SUBMITTED,
        nextState: ROUTE_TO_SUCCESS_SCREEN,
        prop: 'updatedEmail',
      }),
      transition(
        'noAppointmentDuringSubmission',
        ROUTE_TO_APPOINTMENT_SCREEN,
        reduce((ctx: machineContext) => ({ ...ctx, refetchAppointments: true }))
      ),
      transition(
        GENERIC_SUBMISSION_ERROR,
        ROUTE_TO_APPOINTMENT_SCREEN,
        reduce((ctx: machineContext, { error }) => {
          Sentry.captureException(error)
          return {
            ...ctx,
            refetchAppointments: true,
            error,
            showGenericErrorDialog: true,
          }
        })
      ),
      changeStateWithoutNavigation(BASE_APPOINTMENT_SCREEN),
      changeStateWithoutNavigation(BASE_VIN_LP_SCREEN),
      transition(RESET, SEND_RESET_STATE)
    ),

    // booking complete screen
    baseBookingCompleteScreen: state(
      transition('bookingComplete', 'doneForNow'),
      transition(BASE_FINAL_DETAILS_SCREEN, 'base404Screen')
    ),
    doneForNow: state(),

    // routing
    routeToAddressScreen: invoke(
      (ctx: machineContext) => routeTo(`/book-now?${ctx.queryParams}`),
      transition(DONE, BASE_ADDRESS_SCREEN),
      transition(ERROR, ERROR_STATE)
    ),
    routeToAppointmentScreen: invoke(
      (ctx: machineContext) => routeTo(`/book-now/select-appointment?${ctx.queryParams}`),
      transition(DONE, BASE_APPOINTMENT_SCREEN),
      transition(ERROR, ERROR_STATE)
    ),
    routeToVinLpScreen: invoke(
      (ctx: machineContext) => routeTo(`/book-now/vehicle-id?${ctx.queryParams}`),
      transition(DONE, BASE_VIN_LP_SCREEN),
      transition(ERROR, ERROR_STATE)
    ),
    routeToFinalDetailsScreen: invoke(
      (ctx: machineContext) => routeTo(`/book-now/final-details?${ctx.queryParams}`),
      transition(DONE, BASE_FINAL_DETAILS_SCREEN),
      transition(ERROR, ERROR_STATE)
    ),
    routeToSuccessScreen: invoke(
      (ctx: machineContext) => routeTo(`/book-now/success?${ctx.queryParams}`),
      transition(DONE, BASE_BOOKING_COMPLETE_SCREEN),
      transition(ERROR, ERROR_STATE)
    ),

    // not found, 404
    base404Screen: invoke(
      (ctx: machineContext) => routeTo(`/404?${ctx.queryParams}`),
      transition(
        DONE,
        ERROR_STATE,
        reduce((ctx: machineContext) => ({
          ...ctx,
          appointment: undefined,
          sameDayApptsAvail: undefined,
          nextDayApptsAvail: undefined,
          vinLp: undefined,
          updatedEmail: undefined,
        }))
      )
    ),

    // errors
    outOfServiceAreaError: state(immediate(BASE_ADDRESS_SCREEN)),
    // TODO: this should be dynamic to send back to either the address page or the estimate page, depending on self-booking status
    resetState: invoke(
      (ctx: machineContext) => routeTo(`/book-now?${ctx.queryParams}`),
      transition(
        DONE,
        BASE_ADDRESS_SCREEN,
        reduce((ctx: machineContext) => ({
          ...ctx,
          ...context,
          appointment: undefined,
          sameDayApptsAvail: undefined,
          nextDayApptsAvail: undefined,
          vinLp: undefined,
          updatedEmail: undefined,
        }))
      ),
      transition(ERROR, ERROR_STATE)
    ),
    errorState: state(),
  },
  context
)
