import { createMachine, invoke, reduce, state, transition } from 'robot3'
import router from 'next/router'
import cloneDeep from 'lodash/cloneDeep'
import { addOnOptions, standaloneOptions, symptomOptions } from '../../lib/constants'

const routeTo = async (url: string) => {
  return router.push(url)
}

type SymptomFormValue = {
  label: string
  value: string
  selected: boolean
}

type ServiceFormValue = {
  label: string
  value: string
  selected: boolean
  helperText?: string
}

export type Axle = {
  label: string
  value: string
}

export interface machineContext {
  queryParams: string
  make?: string
  model?: string
  vehicleYear?: string
  impactedAxle: Axle
  selectedSymptoms: SymptomFormValue[]
  fullName: string
  phoneNumber: string
  email: string
  zip: string
  serviceUrgency: string
  hearAboutUs: string
  selectedStandalone: ServiceFormValue[]
  selectedAddOn: ServiceFormValue[]
}

const context = (): machineContext => ({
  queryParams: '',
  impactedAxle: null,
  selectedSymptoms: [
    ...symptomOptions.map((symptom) => {
      return {
        ...symptom,
        selected: false,
      }
    }),
  ],
  fullName: '',
  phoneNumber: '',
  email: '',
  zip: '',
  serviceUrgency: '',
  hearAboutUs: '',
  selectedStandalone: [
    ...standaloneOptions.map((service) => {
      return {
        ...service,
        selected: false,
      }
    }),
  ],
  selectedAddOn: [
    ...addOnOptions.map((service) => {
      return {
        ...service,
        selected: false,
      }
    }),
  ],
})

// generic transition function for saving a single field to the context, allows for single level depth updates i.e. event["data"], but not event["data"]["field"]
// use to replace this oft-seen pattern:
// transition(
//   "event",
//   "nextState",
//   reduce((ctx: machineContext, event) => ({ ...ctx, field: event["data"] }))
// );
const saveToContextTransition = (eventName: string, nextState: string, prop: string, accessor: string) => {
  return transition(
    eventName,
    nextState,
    reduce((ctx: machineContext, event) => ({ ...cloneDeep(ctx), [prop]: event[accessor] }))
  )
}

export const leadMachine = createMachine(
  {
    servicesScreen: state(
      transition(
        'servicesSubmitted',
        'servicesSubmitted',
        reduce(
          (
            ctx: machineContext,
            { impactedAxle, selectedStandalone, selectedAddOn, selectedSymptoms, vehicleYear, make, model, queryParams }
          ) => ({
            ...ctx,
            impactedAxle,
            selectedStandalone,
            selectedAddOn,
            selectedSymptoms,
            vehicleYear,
            make,
            model,
            queryParams,
          })
        )
      )
    ),
    servicesSubmitted: invoke(
      (ctx: machineContext) => routeTo(`/get-a-quote/add-info?${ctx.queryParams}`),
      transition('done', 'customerInfoScreen'),
      transition('error', 'errorState')
    ),
    customerInfoScreen: state(
      saveToContextTransition('fullName', 'customerInfoScreen', 'fullName', 'data'),
      saveToContextTransition('phoneNumber', 'customerInfoScreen', 'phoneNumber', 'data'),
      saveToContextTransition('email', 'customerInfoScreen', 'email', 'data'),
      saveToContextTransition('zip', 'customerInfoScreen', 'zip', 'data'),
      saveToContextTransition('serviceUrgency', 'customerInfoScreen', 'serviceUrgency', 'data'),
      saveToContextTransition('hearAboutUs', 'customerInfoScreen', 'hearAboutUs', 'data'),
      transition('quoteRequested', 'doneForNow'),
      transition('servicesScreen', 'servicesScreen')
    ),
    doneForNow: state(),

    // errors
    errorState: invoke(
      (ctx: machineContext) => routeTo(`/get-a-quote/services?${ctx.queryParams}`),
      transition(
        'done',
        'servicesScreen',
        reduce((ctx: machineContext) => ({
          ...ctx,
          ...context,
        }))
      ),
      transition('error', 'finalErr')
    ),
  },
  context
)
