import { ReactElement, ReactNode, useEffect, useState } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { theme } from '../styles/theme'
import createEmotionCache from '../styles/createEmotionCache'
import '../styles/styles.css'
import { SelfScheduleMachineProvider } from '../hooks/useSelfScheduleMachine'
import { LeadMachineProvider } from '../hooks/useLeadMachine'
import { ApolloProvider } from '@apollo/client'
import client from '../lib/apolloClient'
import * as queryString from 'query-string'
import { pascalCase } from 'change-case'
import { includes } from 'ramda'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { LogOnMount, Segment, SegmentProvider } from '../lib/segment'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

// this is the dev key
const defaultSegmentWriteKey = 'y14RXKxfEdlmSbWR25qwCOGlAMPmgQt1'
const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || defaultSegmentWriteKey
// https://abdessalam.dev/blog/detect-device-type-javascript/
const getDeviceCategory = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'Tablet'
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'Mobile'
  }
  return 'Desktop'
}
// Create a GrowthBook instance
const growthbook = new GrowthBook({
  trackingCallback: (exp, result) => {
    console.log({ exp, result })
  },
})

const CUSTOMER_INFO_PATHNAME = '/get-a-quote'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPageWithLayout
}

export default function MyApp(props: MyAppProps) {
  useEffect(() => {
    // Load feature definitions from API
    fetch(process?.env?.NEXT_PUBLIC_GROWTHBOOK_URL)
      .then((res) => res.json())
      .then((json) => {
        growthbook.setFeatures(json.features)
      })
  }, [])
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  // this allows us to render only on the client side
  const [rendered, setRendered] = useState(false)
  const [landingPagePathname, setLandingPagePathname] = useState(null)
  useEffect(() => {
    setRendered(true)
    setLandingPagePathname(window.location.pathname)
  }, [])

  if (!rendered) {
    return null
  }

  // allows custom layout per page
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <CacheProvider value={emotionCache}>
      <ApolloProvider client={client}>
        <GrowthBookProvider growthbook={growthbook}>
          <LeadMachineProvider>
            <SelfScheduleMachineProvider>
              <Head>
                <title>NuBrakes | Mobile Brake Repair</title>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <script
                  async
                  src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_MAP_KEY}&libraries=places`}
                />
              </Head>
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <SegmentProvider writeKey={segmentWriteKey}>
                  <Segment
                    eventProperties={{
                      scope: ['NuBrakes'],
                      deviceCategory: getDeviceCategory(),
                      landingPagePathname: landingPagePathname ?? window.location.pathname,
                      currentPagePathname: window.location.pathname,
                      ...Object.assign(
                        {},
                        ...Object.keys(queryString.parse(window.location.search))
                          .filter((key) => !includes(key, ['vehicleYear', 'vehicleMake', 'vehicleModel'])) //filter YMM out of params that we feed to Segment as part of the landingPageUrl since we send them with the form events
                          .map((key) => ({
                            ['landingPageUrl' + pascalCase(key)]: queryString.parse(window.location.search)[key],
                          }))
                      ),
                    }}
                  >
                    {!window.location.pathname.startsWith(CUSTOMER_INFO_PATHNAME) && (
                      <>
                        <LogOnMount eventType="Session Start" />
                      </>
                    )}
                    {getLayout(<Component {...pageProps} />)}
                  </Segment>
                </SegmentProvider>
              </ThemeProvider>
            </SelfScheduleMachineProvider>
          </LeadMachineProvider>
        </GrowthBookProvider>
      </ApolloProvider>
    </CacheProvider>
  )
}
