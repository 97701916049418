export const serviceUrgencyOptions = ['Today', 'Tomorrow', 'Within a week', 'In the next few weeks']

export const hearAboutUsOptions = [
  'Referred By Friend',
  'NuBrakes Van',
  'Google Maps',
  'Google Search',
  'Radio',
  'TV',
  'YouTube',
  'Yelp',
  'Facebook / Instagram',
  'Nextdoor',
  'Pandora',
  'Spotify',
  'Bing',
  'Billboard',
  'Other',
]

export const zipRegex = /^\d{5}$/
export const phoneRegex = /^[0-9]{10}$/

export const axleOptions = [
  { label: 'Front', value: 'front' },
  { label: 'Rear', value: 'rear' },
  { label: 'Both', value: 'both' },
  { label: `I don't know`, value: 'unknown' },
]
export const symptomOptions = [
  { label: 'I need new pads / shoes', value: 'newPadsShoes' },
  { label: 'I need new rotors / drums', value: 'newRotorsDrums' },
  { label: 'Squeaking / squealing', value: 'squeakingSquealing' },
  { label: 'Grinding / scraping', value: 'grindingScraping' },
  { label: 'Vibrating / shaking / pulsing', value: 'vibratingShakingPulsing' },
  { label: 'Pedal pressure issue', value: 'pedalPressureIssue' },
  { label: 'Something else', value: 'somethingElse' },
]

export const standaloneOptions = [
  { label: 'Synthetic Oil Change', value: 'syntheticOilChange', helperText: 'Change every 3 mo / 5,000 miles' },
  {
    label: 'General Maintenance',
    value: 'generalMaintenance',
    helperText: 'Oil Change, Tire Rotation, Filters & Wipers',
  },
  { label: 'Battery Replacement', value: 'batteryReplacement', helperText: 'Replace every 40,000 miles' },
]

export const addOnOptions = [
  { label: 'Tire Rotation', value: 'tireRotation', helperText: 'Rotate every 3 months / 3,000 miles' },
  { label: 'Engine Air Filter', value: 'engineAirFilter', helperText: 'Replace every 13,000 miles' },
  { label: 'Cabin Air Filter', value: 'cabinAirFilter', helperText: 'Replace every 30,000 miles' },
  { label: 'Front Windshield Wipers', value: 'frontWipers', helperText: 'Replace every 6-12 months' },
  { label: 'Rear Windshield Wipers', value: 'rearWipers', helperText: 'Replace every 6-12 months' },
  { label: 'Wiper Fluid Top Off', value: 'wiperFluid' },
  { label: 'Tire Pressure Check & Fill', value: 'tirePressure' },
  { label: 'Replace Headlights', value: 'headlights', helperText: 'Replace every 60,000 miles' },
  { label: 'Replace Tail Lights', value: 'taillights', helperText: 'Replace every 60,000 miles' },
]

export const EJI_DISCOUNT_TYPE_PERCENT = 'Percent'
export const EJI_DISCOUNT_TYPE_FLAT = 'Flat'
