/**
 * Market information including phone numbers and time zone.
 */
const MarketDetails: {
  [city: string]: {
    phoneNumber: string
    phoneNumberTxt: string
    timeZone?: string
  }
} = {
  default: {
    phoneNumberTxt: '(855) 800-5629',
    phoneNumber: '8558005629',
  },
  support: {
    phoneNumberTxt: '(833) 408-0604',
    phoneNumber: '8334080604',
  },
  austin: {
    phoneNumberTxt: '(512) 640-2560',
    phoneNumber: '5126402560',
    timeZone: 'America/Chicago',
  },
  'san antonio': {
    phoneNumberTxt: '(210) 794-6444',
    phoneNumber: '2107946444',
    timeZone: 'America/Chicago',
  },
  'san-antonio': {
    phoneNumberTxt: '(210) 794-6444',
    phoneNumber: '2107946444',
    timeZone: 'America/Chicago',
  },
  sanantonio: {
    phoneNumberTxt: '(210) 794-6444',
    phoneNumber: '2107946444',
    timeZone: 'America/Chicago',
  },
  dallas: {
    phoneNumberTxt: '(469) 706-3497',
    phoneNumber: '4697063497',
    timeZone: 'America/Chicago',
  },
  'fort worth': {
    phoneNumberTxt: '(469) 706-3497',
    phoneNumber: '4697063497',
    timeZone: 'America/Chicago',
  },
  'fort-worth': {
    phoneNumberTxt: '(469) 706-3497',
    phoneNumber: '4697063497',
    timeZone: 'America/Chicago',
  },
  houston: {
    phoneNumberTxt: '(832) 706-4115',
    phoneNumber: '8327064115',
    timeZone: 'America/Chicago',
  },
  atlanta: {
    phoneNumberTxt: '(470) 467-7736',
    phoneNumber: '4704677736',
    timeZone: 'America/New_York',
  },
  nashville: {
    phoneNumberTxt: '(629) 206-1979',
    phoneNumber: '6292061979',
    timeZone: 'America/Chicago',
  },
  chattanooga: {
    phoneNumberTxt: '(423) 800-5960',
    phoneNumber: '4238005960',
    timeZone: 'America/New_York',
  },
  knoxville: {
    phoneNumberTxt: '(865) 800-8351',
    phoneNumber: '8658008351',
    timeZone: 'America/New_York',
  },
  tampa: {
    phoneNumberTxt: '(813) 957-9076',
    phoneNumber: '8139579076',
    timeZone: 'America/New_York',
  },
  orlando: {
    phoneNumberTxt: '(689) 216-4829',
    phoneNumber: '6892164829',
    timeZone: 'America/New_York',
  },
  miami: {
    phoneNumberTxt: '(786) 780-2495',
    phoneNumber: '7867802495',
    timeZone: 'America/New_York',
  },
}

export const getMarketDetailsByName = (marketName: string | undefined) => {
  return MarketDetails[marketName] ?? MarketDetails.default
}
